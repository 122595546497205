import React, { useState, useEffect } from "react";
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Tag, Input, Button, Row, Col, Card, Image, Upload, notification, Space, Select,  } from "antd";
import {useLocation} from 'react-router-dom'
import FormData from 'form-data'
import axios from 'axios'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';

const {TextArea} = Input
const {Option} = Select

const Detail = () => {
    const token = localStorage.getItem("token")
    const loc = useLocation()
    const [name, setname] = useState("")
    const [short_desc, setshortd] = useState("")
    const [long_desc, setlongd] = useState("")
    const [service_icon, setimage] = useState("")
    const [service_banner, setbanner] = useState("")
    const [prevUrl, setprev] = useState({
        service_icon: "",
        service_banner: "",
    })

    const [oriData, setoridata] = useState({})
    const [loading, setLoading] = useState(false)

    const getData = async (id) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/service/' + id , {
            headers: {
          Authorization: token
        }})

        if(fetch) {
            let data = fetch.data.response
            let imageUrl = {
                service_icon: data.icon_source,
                service_banner: data.single_banner_source,
            }
            setimage(data.icon_source)
            setbanner(data.single_banner_source)
            setprev(imageUrl)
            setname(data.name)
            setshortd(data.short_desc)
            setlongd(data.long_desc)
            setoridata({...data, ...imageUrl})
        }
      }

      useEffect(() => {
        if(loc.search && loc.search !== "") {
            getData(loc.search.split("?")[1].split("=")[1])
        }
      }, [])

      const submitForm = async (e) => {
          try {
              setLoading(true)
              e.preventDefault()
              let state = {
                    name,
                    short_desc,
                    service_icon,
                }
                let isEmpty = false
                let body = new FormData()
                for(let key in state) {
                    if(state[key] === '' || state[key] === null || state[key] === undefined || state[key].length === 0) {
                        isEmpty = true
                    }
                }

                if(!isEmpty) {
                    if(loc.search && loc.search !== "") {
                        let id = loc.search.split("?")[1].split("=")[1]
                        let body = new FormData()
                        for(let key in state) {
                            if((oriData[key] != state[key]) && state[key] !== undefined) {
                                body.append(key,state[key])
                                console.log(key, 'bedaa', oriData[key], state[key])
                            }
                        }
      
                        let update = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/service/' + id,
                            body,{headers: {
                            Authorization: token,
                            'Content-Type': `multipart/form-data; boundary=${body._boundary}`,
                        }})
    
                        if(update) {
                            setLoading(false)
                            notification["success"]({
                                message: "Success Edit Benefit detail",
                            });
                            window.location.href = "/dashboard/service"
                        }
                        
                        
                      } else {
                        for(let key in state) {
                            body.append(key, state[key])
                        }
                        let create = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/service/', 
                        body,
                        {headers: {
                            Authorization: token
                        }})

                        if(create) {
                            setLoading(false)
                            notification["success"]({
                                message: "Success Edit Service info",
                            });
                            getData()
                        }
                    }
                    
                } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        long_desc:
                            "Please try again",
                        });
                }
          } catch (error) {

            console.log(error)
            setLoading(false)
            notification["warning"]({
                message: "Error",
                long_desc:
                  "Please try again",
              });
          }
      }

      const changeImg = async (key, { file, fileList }) => {
        if (file.status !== 'uploading') {
            if(key === 'service_icon') {
                setimage(file)
            } else if(key === 'service_banner') {
                setbanner(file)
            }
            const src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
        
                reader.onloadend = () => resolve(reader.result);
              });

              setprev({...prevUrl, [key]:src})
            console.log(file);
        }
    }

    return (
        <React.Fragment>
            <Card size="small" style={{padding: "20px 30px"}}>
                <Row justify="space-between">
                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>name</p>
                        <Input value={name} onChange={(e) => setname(e.target.value)}/>
                    </Col>
                    
                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Short Description</p>
                        <TextArea rows={4} value={short_desc} onChange={(e) => setshortd(e.target.value)}/>
                    </Col>

                    {/* <Col span={24} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>long_desc</p>
                        <TextArea rows={15} value={long_desc} onChange={(e) => setlongd(e.target.value)}/>
                    </Col> */}

                    <Col span={11} style={{marginTop: 25}}>
                        <p style={{marginBottom: 5}}>Service icon</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            paddingLeft: '11px',
                        }} wrap>
                            <div style={{width: '73%'}}>
                                <p className="elipsis" style={{marginBottom: '0px', marginTop: '4px'}}>{service_icon?.name ? service_icon.name : service_icon}</p>
                            </div>
                            
                            <Upload 
                            style={{height: '100%'}}
                            showUploadList={false}
                            beforeUpload={() => {
                                    return false;
                                }}                      
                                onChange={(data) => changeImg('service_icon', data)}>
                                    <Button style={{borderWidth: 0, borderLeftWidth: '1px', height: '100%'}} icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                            <Image src={prevUrl.service_icon} style={{marginTop: '20px'}}/>
                    </Col>

                    {/* <Col span={11} style={{marginTop: 25}}>
                        <p style={{marginBottom: 5}}>Service Banner</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            paddingLeft: '11px',
                        }} wrap>
                            <div style={{width: '73%'}}>
                                <p className="elipsis" style={{marginBottom: '0px', marginTop: '4px'}}>{service_banner?.name ? service_banner.name : service_banner}</p>
                            </div>
                            
                            <Upload 
                            style={{height: '100%'}}
                            showUploadList={false}
                            beforeUpload={() => {
                                    return false;
                                }}                      
                                onChange={(data) => changeImg('service_banner', data)}>
                                    <Button style={{borderWidth: 0, borderLeftWidth: '1px', height: '100%'}} icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                            <Image src={prevUrl.service_banner} style={{marginTop: '20px'}}/>
                    </Col> */}
 
                    <Col span={24}>
                        <Button style={{float: 'right', marginTop: 25}} type="primary" loading={loading} onClick={submitForm}>Submit</Button>
                    </Col>
                    
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default Detail