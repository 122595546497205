import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Card, Table, Tag, Space } from "antd";
import {Link} from 'react-router-dom'
import styled from "styled-components";

import logo from '../../../assets/logo.png'
import axios from "axios";

const Product = () => {
  const token = localStorage.getItem("token")
  const [data, setData] = useState([])
    const columns = [
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <Link to={`/dashboard/benefit/detail?id=${record.id}`}>
                <Tag color={'geekblue'}>
                  Ubah
                </Tag>
              </Link>
            </Space>
          ),
        },
      ];

      const getList = async () => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/benefit', {headers: {
          Authorization: token
        }})

        if(fetch) {
          setData(fetch.data.response.rows)
          console.log(fetch)
        }
      }

      useEffect(() => {
        getList()
      }, [])
    return (
        <div>
            <React.Fragment>
                <Card size="small" style={{padding: "20px 30px", marginTop: '30px'}}>
                    <Table columns={columns} dataSource={data} pagination={false}/>
                </Card>
            </React.Fragment>
            
        </div>
    )
}

export default Product