import React from "react";
import { Layout, Menu, Space } from "antd";
import {
  UsergroupAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Outlet, Link, useLocation, Navigate, useNavigate } from "react-router-dom";

import "../../App.css";

import Product from "./Product";

const { Header, Sider, Content } = Layout;

const Dashboard = () => {
//   const role = localStorage.getItem("role");
  const navigate = useNavigate()
  const {pathname} = useLocation();
//   const history = useHistory();

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/", {replace: true})
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to="/" replace={true} />;
  }
let path = pathname.split("/")[1]
  return (
      <React.Fragment>
          <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="logo" style={{ display: "flex", justifyContent: "center" }}>
          <img src={require("../../assets/logo.png")} width="40%"  alt="raja rental" />
        </div>
        <Menu theme="dark" mode="inline" 
        defaultSelectedKeys={path}
        >
            <Menu.Item key="admin">
              <Link to="admin">Admin</Link>
            </Menu.Item>
            <Menu.Item key="home-banner">
              <Link to="home-banner">Home Banner</Link>
            </Menu.Item>
            <Menu.Item key="benefit">
              <Link to="benefit">Benefit</Link>
            </Menu.Item>
            <Menu.Item key="home-professional">
              <Link to="home-professional">Home Professional</Link>
            </Menu.Item>
            <Menu.Item key="service">
              <Link to="service">Service</Link>
            </Menu.Item>
            <Menu.Item key="testimonial">
              <Link to="testimonial">Testimonial</Link>
            </Menu.Item>
            <Menu.Item key="partner">
              <Link to="partner">Partner</Link>
            </Menu.Item>
            <Menu.Item key="variant">
              <Link to="variant">Product Variant</Link>
            </Menu.Item>
            <Menu.Item key="product-category">
              <Link to="product-category">Product Category</Link>
            </Menu.Item>
            <Menu.Item key="product">
              <Link to="product">Product</Link>
            </Menu.Item>
            <Menu.Item key="how-to-order">
              <Link to="how-to-order">How To Order</Link>
            </Menu.Item>
            
            <Menu.Item key="article">
              <Link to="article">News / Portofolio</Link>
            </Menu.Item>
            <Menu.Item key="about-us">
              <Link to="about-us">About Us</Link>
            </Menu.Item>
            <Menu.Item key="faq">
              <Link to="faq">FAQ</Link>
            </Menu.Item>
             <Menu.Item key="email">
              <Link to="email">Email Contact</Link>
            </Menu.Item>
            <Menu.Item key="wa">
              <Link to="whatsapp">WhatsApp Number</Link>
            </Menu.Item>
            <Menu.Item key="content-footer">
              <Link to="content-footer">Content Footer</Link>
            </Menu.Item>
            <Menu.Item
                key="0"
                style={{ color: "red" }}
                onClick={logout}
                icon={<LogoutOutlined />}
            >
                Logout
            </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: 200, background: "#FFF" }}
      >
        <Header
          style={{
            // position: "fixed",
            // zIndex: 1,
            width: "100%",
            height: "68px",
            background: "#012240",
          }}
        >
          <div style={{ color: "#FFF" }}>{pathname?.split("/").join(" ").toUpperCase()}</div>
        </Header>
        {/* <h1>Test</h1> */}
        <Content 
        style={{
            padding: '40px 50px',
          }}
        // direction="vertical"
        // size="middle"
        // style={{
        //   display: 'flex',
        // }}
        >
            <Outlet/>
        </Content>
        
            {/* <Route exact path="/dashboard/1/product">
                <Product />
            </Route> */}
      </Layout>
    </Layout>
      </React.Fragment>
    
  );
};

export default Dashboard;
