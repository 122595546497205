import React, { useState, useEffect } from "react";
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Tag, Input, Button, Row, Col, Card, Image, Upload, notification, Space, Select,  } from "antd";
import {useLocation} from 'react-router-dom'
import FormData from 'form-data'
import axios from 'axios'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const {TextArea} = Input
const {Option} = Select

const Detail = () => {
    const token = localStorage.getItem("token")
    const loc = useLocation()
    const [title, settitle] = useState("")
    const [subtitle, setsubtitle] = useState("")
    const [content, setcontent] = useState("")
    const [tags, settags] = useState([])
    const [article_type, settype] = useState("")
    const [article_image, setimage] = useState("")
    const [article_banner, setbanner] = useState("")
    const [article_thumbnail, setthumbnail] = useState("")
    const [prevUrl, setprev] = useState({
        article_image: "",
        article_banner: "",
        article_thumbnail: ""
    })

    const [oriData, setoridata] = useState({})
    const [loading, setLoading] = useState(false)
    const [inputTagVisible, setinputvisible] = useState(false)
    const [inputTags, setinputtags] = useState("")

    const getData = async (id) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/article/' + id, {headers: {
          Authorization: token
        }})

        if(fetch) {
            let data = fetch.data.response
            let imageUrl = {
                article_image: data.image_source,
                article_banner: data.banner_source,
                article_thumbnail: data.thumbnail_source
            }
            console.log(data.tags.split(","))
            setimage(data.image_source)
            setbanner(data.banner_source)
            setthumbnail(data.thumbnail_source)
            setprev(imageUrl)
          setoridata({...data, ...imageUrl, tags: data.tags.split(",")})
          settitle(data.title)
          setsubtitle(data.subtitle)
          setcontent(data.content)
          settags(data.tags.split(","))
          settype(data.article_type)
        }
      }

      useEffect(() => {
        if(loc.search && loc.search !== "") {
            getData(loc.search.split("?")[1].split("=")[1])
        }
      }, [])

      const submitForm = async (e) => {
          try {
              setLoading(true)
              e.preventDefault()
              let state = {
                    title,
                    subtitle,
                    content,
                    tags,
                    article_type,
                    article_image,
                    article_banner
                }
                if(article_type === 'news') {
                    state.article_thumbnail = article_thumbnail
                }
                let isEmpty = false
                for(let key in state) {
                    if(state[key] === '' || state[key] === null || state[key] === undefined || state[key].length === 0) {
                        isEmpty = true
                    }
                }

                if(loc.search && loc.search !== "") {
                  let id = loc.search.split("?")[1].split("=")[1]
                  let body = new FormData()
                  for(let key in state) {
                      if(key === 'tags') {
                          let isDif = false
                          oriData[key].map(data => {isDif = state[key].findIndex(a => a === data) == -1 ? true : false})
                          state[key].map(data => {isDif = oriData[key].findIndex(a => a === data) == -1 ? true : false})
                          if(isDif){body.append(key, state[key].join())}
                      } else {
                          if((oriData[key] != state[key]) && state[key] !== undefined) {
                            body.append(key,state[key])
                            console.log(key, 'bedaa', oriData[key], state[key])
                          }
                      }
                  }

                  if(!isEmpty) {
                      let update = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/article/' + id,
                          body,{headers: {
                        Authorization: token,
                        'Content-Type': `multipart/form-data; boundary=${body._boundary}`,
                    }})

                    if(update) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Edit Benefit detail",
                        });
                        window.location.href = "/dashboard/article"
                    }
                  } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        description:
                          "Please try again",
                      });
                  }
                  
                } else {
                    let body = new FormData()
                    for(let key in state) {
                        body.append(key, state[key])
                    }
                    if(!isEmpty) {
                        let create = await axios.post('https://api.rajarental.co.id/api/v1.0/admin/article/', 
                        body,
                        {headers: {
                            Authorization: token
                        }})

                        if(create) {
                            setLoading(false)
                            notification["success"]({
                                message: "Success Add Article",
                            });
                            window.location.href = "/dashboard/article"
                        }
                    } else {
                        setLoading(false)
                        notification["warning"]({
                            message: "Semua field wajib diisi",
                            description:
                              "Please try again",
                          });
                    }
                }
          } catch (error) {

            console.log(error)
            setLoading(false)
            notification["warning"]({
                message: "Error",
                description:
                  "Please try again",
              });
          }
      }

      const changeImg = async (key, { file, fileList }) => {
        if (file.status !== 'uploading') {
            if(key === 'article_image') {
                setimage(file)
            } else if(key === 'article_banner') {
                setbanner(file)
            } else if(key === 'article_thumbnail') {
                setthumbnail(file)
            }
            const src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
        
                reader.onloadend = () => resolve(reader.result);
              });

              setprev({...prevUrl, [key]:src})
            console.log(file);
        }
    }
    // console.log(username)

    const deleteTag = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        console.log(newTags);
        settags(newTags);
      };

    const addTag = () => {
        if (inputTags && tags.indexOf(inputTags) === -1) {
          settags([...tags, inputTags]);
        }
    
        setinputvisible(false);
        setinputtags('');
      };

      const onEditorStateChange = (editorState) => {
          console.log('heh', editorState)
        setcontent(editorState)
      }

      console.log(content, 'cont')
    return (
        <React.Fragment>
            <Card size="small" style={{padding: "20px 30px"}}>
                <Row justify="space-between">
                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Title</p>
                        <Input value={title} onChange={(e) => settitle(e.target.value)}/>
                    </Col>
                    
                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Subtitle</p>
                        <Input value={subtitle} onChange={(e) => setsubtitle(e.target.value)}/>
                    </Col>

                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Tags</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: 2,
                            padding: '4px 11px 0 11px'
                        }}>
                            {
                                tags?.map((tag) => (
                                    <Tag
                                        key={tag}
                                        closable={true}
                                        style={{marginBottom: 4}}
                                        onClose={() => deleteTag(tag)}                
                                    >{tag}</Tag>
                                ))
                            }
                            {inputTagVisible && (
                                <Input
                                // ref={inputRef}
                                type="text"
                                size="small"
                                className="tag-input"
                                value={inputTags}
                                onChange={(e) => setinputtags(e.target.value)}
                                onBlur={addTag}
                                onPressEnter={addTag}
                                />
                            )}
                            {!inputTagVisible && (
                                <Tag className="site-tag-plus" onClick={() => setinputvisible(true)}>
                                <PlusOutlined /> New Tag
                                </Tag>
                            )}
                        </div>
                    </Col>

                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Article Type</p>
                        <Select style={{display: 'block'}} 
                            value={article_type} 
                            onChange={(e) => settype(e)}
                        >
                            <Option value={"portfolio"} title="Portofolio"/>
                            <Option value={"news"} title="News"/>
                        </Select>
                    </Col>

                    <Col span={24} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Content</p>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={content}
                                config={{
                                    fontFamily: [ 'League Spartan' ]
                                }}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setcontent(data)
                                    console.log( { event, editor, data } );
                                } }
                            />
                            {/* <TextArea rows={16} value={content} onChange={(e) => setcontent(e.target.value)}/> */}

                            {/* <Editor
                                editorState={content}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                            /> */}
                    </Col>


                    <Col span={11} style={{marginTop: 25}}>
                        <p style={{marginBottom: 5}}>Article Image</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            paddingLeft: '11px',
                        }} wrap>
                            <div style={{width: '73%'}}>
                                <p className="elipsis" style={{marginBottom: '0px', marginTop: '4px'}}>{article_image?.name ? article_image.name : article_image}</p>
                            </div>
                            
                            <Upload 
                            style={{height: '100%'}}
                            showUploadList={false}
                            beforeUpload={() => {
                                    return false;
                                }}                      
                                onChange={(data) => changeImg('article_image', data)}>
                                    <Button style={{borderWidth: 0, borderLeftWidth: '1px', height: '100%'}} icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                            <Image src={prevUrl.article_image} style={{marginTop: '20px'}}/>
                    </Col>

                    <Col span={11} style={{marginTop: 25}}>
                        <p style={{marginBottom: 5}}>Article Banner</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            paddingLeft: '11px',
                        }} wrap>
                            <div style={{width: '73%'}}>
                                <p className="elipsis" style={{marginBottom: '0px', marginTop: '4px'}}>{article_banner?.name ? article_banner.name : article_banner}</p>
                            </div>
                            
                            <Upload 
                            style={{height: '100%'}}
                            showUploadList={false}
                            beforeUpload={() => {
                                    return false;
                                }}                      
                                onChange={(data) => changeImg('article_banner', data)}>
                                    <Button style={{borderWidth: 0, borderLeftWidth: '1px', height: '100%'}} icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                            <Image src={prevUrl.article_banner} style={{marginTop: '20px'}}/>
                    </Col>

                    {
                        article_type === 'news' &&
                        <Col span={11} style={{marginTop: 25}}>
                            <p style={{marginBottom: 5}}>Article Thumbnail</p>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                border: '1px solid #d9d9d9',
                                borderRadius: '2px',
                                paddingLeft: '11px',
                            }} wrap>
                                <div style={{width: '73%'}}>
                                    <p className="elipsis" style={{marginBottom: '0px', marginTop: '4px'}}>{article_thumbnail?.name ? article_thumbnail.name : article_thumbnail}</p>
                                </div>
                                
                                <Upload 
                                style={{height: '100%'}}
                                showUploadList={false}
                                beforeUpload={() => {
                                        return false;
                                    }}                      
                                    onChange={(data) => changeImg('article_thumbnail', data)}>
                                        <Button style={{borderWidth: 0, borderLeftWidth: '1px', height: '100%'}} icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                                <Image src={prevUrl.article_thumbnail} style={{marginTop: '20px'}}/>
                        </Col>
                    }

                    
 
                    <Col span={24}>
                        <Button style={{float: 'right', marginTop: 25}} type="primary" loading={loading} onClick={submitForm}>Submit</Button>
                    </Col>
                    
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default Detail