import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Card, Table, Tag, Space } from "antd";
import {Link, useNavigate, useLocation} from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import logo from '../../../assets/logo.png'
import axios from "axios";
const { confirm } = Modal;

const Product = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const token = localStorage.getItem("token")
  const [data, setData] = useState([])
  const [total, setTotal] = useState()
  const [current_page, setCurrent] = useState(1)
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <Link to={`/dashboard/variant/detail?id=${record.id}`}>
                <Tag color={'geekblue'}>
                  Ubah
                </Tag>
              </Link>

              <Button danger size="small" onClick={() => {
                confirm({
                  title: 'Do you Want to delete these items?',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    deleteData(record.id)
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                })}}>Delete</Button>
            </Space>
          ),
        },
      ];

      const deleteData = async (id) => {
        let del = await axios.delete('https://api.rajarental.co.id/api/v1.0/admin/variant/' + id, {headers: {
          Authorization: token
        }})

        if(del) {
          getList()
        }
      }

      const getList = async (page) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/variant', {
          headers: {
            Authorization: token
          },
          params: {
            pagination: true,
            page: page ? page : current_page,
            row: 10,
          }
        })

        if(fetch) {
          setData(fetch.data.response.rows)
          setTotal(fetch.data.response.count)
        }
      }

      useEffect(() => {
        if(location.search !== '') {
          let query = location.search.split("=")
          getList(query[1])
          setCurrent(+query[1])
        } else {
          getList()
        }
        
      }, [location]);
    return (
        <div>
            <React.Fragment>
                <Card size="small" style={{padding: "20px 30px", marginTop: '30px'}}>
                    <Row>
                      <Col span={24}>
                        <Button style={{float: 'right', marginBottom: 25}} type="primary" onClick={() => {
                          window.location.href = '/dashboard/variant/detail'
                        }}>Add Variant</Button>
                      </Col>
                    </Row>
                    <Table columns={columns} dataSource={data} 
                    pagination={{
                      current: current_page,
                      total: total,
                      onChange: (page) => {
                        navigate("/dashboard/variant?page=" + page, { replace: true });
                      }
                    }}/>
                </Card>
            </React.Fragment>
            
        </div>
    )
}

export default Product