import React, { useState, useEffect } from "react";
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Button, Row, Col, Card, Image, Upload, notification, Select, Space } from "antd";
import { useLocation } from 'react-router-dom'
import FormData from 'form-data'
import axios from 'axios'

const { TextArea } = Input
const { Option } = Select
const { Dragger } = Upload

const Detail = () => {
    const token = localStorage.getItem("token")
    const loc = useLocation()
    const [name, setname] = useState("")
    const [short_desc, setshort_desc] = useState("")
    const [long_desc, setlong_desc] = useState("")
    const [order_url, setorder_url] = useState("")
    const [variant_id, setvariant] = useState([])
    const [variant_temp, setvartemp] = useState([])
    const [category_id, setcategory] = useState("")
    const [is_lineup, setlineup] = useState(false)
    const [main_product_image, seticon] = useState("")
    const [product_image, setimage] = useState({
        product_image_1: null,
        product_image_2: null,
        product_image_3: null,
        product_image_4: null,
        product_image_5: null,
    })
    const [prevUrl, setprev] = useState({
        main_product_image: "",
        product_image: {}
    })
    const [oriData, setoridata] = useState({})
    const [loading, setLoading] = useState(false)
    const [catOption, setcatOption] = useState([])
    const [varOption, setvarOption] = useState([])

    const getData = async (id) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/product/' + id, {
            headers: {
                Authorization: token
            }
        })

        if (fetch) {
            let data = fetch.data.response
            let ori_prod_image = []
            let imageUrl = {
                main_product_image: data.main_image_source,
                product_image: {}
            }
            for (let key in data) {
                let keys = key.split("_")
                if (keys[0] === 'image' && keys[2] === 'source') {

                    imageUrl.product_image["product_image_" + keys[1]] = data[key]
                }
            }
            let temp = []
            data.variants.map((a => { temp.push(`${a.name} - ${a.value}_${a.id}`) }))
            setvartemp(temp)
            seticon(data.main_image_source)
            setimage(imageUrl.product_image)
            setlineup(data.is_lineup)
            setprev(imageUrl)
            setoridata({ ...data, main_product_image: data.icon_source, product_image: imageUrl.product_image })
            setname(data.name)
            setshort_desc(data.short_desc)
            setlong_desc(data.long_desc)
            setorder_url(data.order_url)
            setcategory(data.category_id)
            setvariant(data.variants)
        }
    }

    const getCategory = async (page) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/product-category', {
            headers: {
                Authorization: token
            },
            params: {
                pagination: false,
            }
        })

        if (fetch) {
            setcatOption(fetch.data.response.rows)
        }
    }

    const getVariant = async (page) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/variant', {
            headers: {
                Authorization: token
            },
            params: {
                pagination: false,
            }
        })

        if (fetch) {
            let data = fetch.data.response.rows
            data.map(a => a.label = a.name + " - " + a.value)
            setvarOption(data)
        }
    }

    useEffect(() => {
        if (loc.search && loc.search !== "") {
            getData(loc.search.split("?")[1].split("=")[1])
        }
        getCategory()
        getVariant()
    }, [])

    const submitForm = async (e) => {
        try {
            setLoading(true)
            e.preventDefault()
            let body = new FormData()
            let state = {
                name,
                short_desc,
                long_desc,
                variant_id,
                is_lineup,
                category_id,
                main_product_image,
                product_image
            }
            let isEmpty = false
            for (let key in state) {
                if (state[key] === '' || state[key] === null || state[key] === undefined || state[key].length === 0) {
                    isEmpty = true
                }
                if (key === "variant_id") {
                    // console.log(variant_id, 'var')
                    variant_id.map(vartId => {
                        // if(!oriData?.variants?.find(vart => vart.id === vartId.id)) {
                        body.append(key, vartId.id)
                        // }
                    })
                } else if ((oriData[key] != state[key]) && state[key] !== undefined) {
                    body.append(key, state[key])
                }

            }
            if (loc.search && loc.search !== "") {
                let id = loc.search.split("?")[1].split("=")[1]

                if (body) {
                    for (let key in product_image) {
                        body.append(key, product_image[key])
                    }

                    let update = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/product/' + id,
                        body, {
                            headers: {
                                Authorization: token,
                                'Content-Type': `multipart/form-data; boundary=${body._boundary}`,
                            }
                    })

                    if (update) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Edit Product",
                        });
                        window.location.href = "/dashboard/product"
                    }
                } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        description:
                            "Please try again",
                    });
                }

            } else {
                if (body) {
                    for (let key in product_image) {
                        body.append("product_image", product_image[key])
                    }

                    let create = await axios.post('https://api.rajarental.co.id/api/v1.0/admin/product/',
                        body,
                        {
                            headers: {
                                Authorization: token,
                                'Content-Type': `multipart/form-data; boundary=${body._boundary}`,
                            }
                        })

                    if (create) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Add Product",
                        });
                        window.location.href = "/dashboard/product"
                    }
                } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        description:
                            "Please try again",
                    });
                }
            }
        } catch (error) {

            console.log(error)
            setLoading(false)
            notification["warning"]({
                message: "Error",
                description:
                    "Pastikan semua field telah terisi",
            });
        }
    }

    const changeImg = async (key, { file, fileList }) => {
        if (file.status !== 'uploading') {
            if (file.status !== "removed") {
                const src = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onloadend = () => resolve(reader.result);
                });
                let temp = { ...prevUrl }
                key.split("_")[0] === 'product' ? temp.product_image = { ...prevUrl.product_image, [key]: src } : temp[key] = src
                setprev(temp)
            } else {
                let temp = { ...prevUrl }
                key.split("_")[0] === 'product' ? temp.product_image = { ...prevUrl.product_image, [key]: null } : temp[key] = null
                setprev(temp)
            }


            if (key === 'main_product_image') {
                seticon(file)
            } else if (key.split("_")[0] === 'product') {
                if (file.status !== "removed") {
                    setimage({ ...product_image, [key]: file })
                } else {
                    setimage({ ...product_image, [key]: "null" })
                }
            }
        }
    }

    const changevar = (value) => {
        let findVal = []
        value.map(val => {
            let temp = varOption.find(vart => vart.id === Number(val.split('_')[1]))
            if (temp) { findVal.push(temp) }
        })
        setvariant(findVal)
        setvartemp(value)
    }
    // console.log(product_image, 'img')

    return (
        <React.Fragment>
            <Card size="small" style={{ padding: "20px 30px" }}>
                <Row justify="space-between">
                    <Col span={11} style={{ marginBottom: 25 }}>
                        <p style={{ marginBottom: 5 }}>Product Name</p>
                        <Input value={name} onChange={(e) => setname(e.target.value)} />
                    </Col>

                    {/* <Col span={11} style={{marginBottom: 25}}>
                        <p style={{marginBottom: 5}}>Order URL</p>
                        <Input value={order_url} onChange={(e) => setorder_url(e.target.value)}/>
                    </Col> */}

                    {/* <Col span={11} style={{marginBottom: 25}}>
                        <p style={{marginBottom: 5}}>Short Description</p>
                        <TextArea rows={6} value={short_desc} onChange={(e) => setshort_desc(e.target.value)}/>
                    </Col> */}

                    <Col span={11} style={{ marginBottom: 25 }}>
                        <p style={{ marginBottom: 5 }}>Long Description</p>
                        <TextArea rows={6} value={long_desc} onChange={(e) => setlong_desc(e.target.value)} />
                    </Col>

                    <Col span={11} style={{ marginBottom: 25 }}>
                        <p style={{ marginBottom: 5 }}>Product Lineup</p>
                        <Select style={{ display: 'block' }}
                            value={is_lineup}
                            onChange={(e) => setlineup(e)}
                        >

                            <Option value={true}>Active</Option>
                            <Option value={false}>Inactive</Option>
                        </Select>
                    </Col>

                    <Col span={11} style={{ marginBottom: 25 }}>
                        <p style={{ marginBottom: 5 }}>Product Category</p>
                        <Select style={{ display: 'block' }}
                            value={category_id}
                            onChange={(e) => setcategory(e)}
                        >
                            {
                                catOption?.map(cat => (
                                    <Option value={cat.id} key={cat.id}>{cat.name}</Option>
                                ))
                            }
                        </Select>
                    </Col>

                    <Col span={11} style={{ marginBottom: 25 }}>
                        <p style={{ marginBottom: 5 }}>Product Variant</p>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Please select"
                            value={variant_temp}
                            onChange={changevar}
                        >
                            {
                                varOption?.map(vart => (
                                    <Option value={`${vart.label}_${vart.id}`} key={vart.id}>{vart.label}</Option>
                                ))
                            }
                        </Select>
                    </Col>

                    <Col span={15} style={{ marginBottom: 25 }}>
                        <p style={{ marginBottom: 5 }}>Product Image</p>
                        <Row>
                            {/* {
                                prevUrl?.product_image?.map((imgUrl, idx) => (
                                    <Image key={idx} src={imgUrl} style={{marginTop: '20px', marginBottom: 15, marginRight: 15, width: 120, height: 120}}/>
                                ))
                            } */}
                            {
                                Object.keys(product_image)?.map(key => (
                                    <Col span={4}>
                                        <Upload
                                            multiple={true}
                                            listType="picture-card"
                                            // showUploadList={false}
                                            fileList={product_image[key] === 'null' || product_image[key] === null ? null : [{ uid: '1', url: prevUrl.product_image[key] }]}
                                            beforeUpload={() => {
                                                return false;
                                            }}
                                            onChange={(data) => changeImg(key, data)}
                                        >

                                            <div style={{
                                                width: 100,
                                                height: 100,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                marginTop: 10
                                            }}>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        </Upload>
                                    </Col>
                                ))
                            }

                        </Row>
                    </Col>
                    <Col span={11} style={{ marginBottom: 25 }}>
                        <p style={{ marginBottom: 5 }}>Main Product Image</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            paddingLeft: '11px',
                        }} wrap>
                            <div style={{ width: '73%' }}>
                                <p className="elipsis" style={{ marginBottom: '0px', marginTop: '4px' }}>{main_product_image?.name ? main_product_image.name : main_product_image}</p>
                            </div>

                            <Upload
                                style={{ height: '100%' }}
                                showUploadList={false}
                                beforeUpload={() => {
                                    return false;
                                }}
                                onChange={(data) => changeImg('main_product_image', data)}>
                                <Button style={{ borderWidth: 0, borderLeftWidth: '1px', height: '100%' }} icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </div>
                        <Image src={prevUrl.main_product_image} style={{ marginTop: '20px' }} />
                    </Col>

                    <Col span={24}>
                        <Button style={{ float: 'right', marginTop: 25 }} type="primary" loading={loading} onClick={submitForm}>Submit</Button>
                    </Col>

                </Row>
            </Card>
        </React.Fragment>
    )
}

export default Detail