import React from "react";
import { BrowserRouter } from "react-router-dom";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import "moment/locale/id";
import "./App.css";
import Components from "./components";

function App() {
  return (
    <BrowserRouter>
      <Components />
    </BrowserRouter>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
