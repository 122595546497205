import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Card, Table, Tag, notification, Select } from "antd";
import {useLocation} from 'react-router-dom'
import styled from "styled-components";
import axios from 'axios'

const {Option} = Select
const {TextArea} = Input

const Detail = () => {
    const token = localStorage.getItem("token")
    const loc = useLocation()
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [phone_number, setphone_number] = useState("")
    const [company, setcompany] = useState("")
    const [question, setquestion] = useState("")
    const [oriData, setoridata] = useState({})
    const [loading, setLoading] = useState(false)

    const getData = async (id) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/email-contact/' + id, {headers: {
          Authorization: token
        }})

        if(fetch) {
            let data = fetch.data.response
          setname(data.name)
          setemail(data.email)
          setphone_number(data.phone_number)
          setcompany(data.company)
          setquestion(data.question)
          setoridata(data)
        }
      }

      useEffect(() => {
        if(loc.search && loc.search !== "") {
            getData(loc.search.split("?")[1].split("=")[1])
        }
      }, [])

    //   const submitForm = async () => {
    //       try {
    //           setLoading(true)
              
    //           if(loc.search && loc.search !== "") {
    //               let id = loc.search.split("?")[1].split("=")[1]
    //               let body = {}
    //               let state = {
    //                   email_to
    //               }
    //               for(let key in oriData) {
    //                   if(oriData[key] != state[key]) {
    //                       body[key] = state[key]
    //                   }
    //               }
    //               if( email_to !== "" ) {
    //                   let update = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/email-contact/' + id,
    //                       body,{headers: {
    //                     Authorization: token
    //                 }})

    //                 if(update) {
    //                     setLoading(false)
    //                     notification["success"]({
    //                         message: "Success Edit Email",
    //                     });
    //                     window.location.href = "/dashboard/email"
    //                 }
    //               } else {
    //                 setLoading(false)
    //                 notification["warning"]({
    //                     message: "Email wajib diisi",
    //                     description:
    //                       "Please try again",
    //                   });
    //               }
                  
    //           }
    //       } catch (error) {

    //         console.log(error)
    //         setLoading(false)
    //         notification["warning"]({
    //             message: "Error",
    //             description:
    //               "Please try again",
    //           });
    //       }
    //   }

    

    

    // console.log(username)

    return (
        <React.Fragment>
            <Card size="small" style={{padding: "20px 30px"}}>
                <Row justify="space-between">
                    <Col span={11} style={{marginBottom: 12}}>
                        <p style={{marginBottom: 5}}>Name</p>
                        <Input value={name}/>
                    </Col>
                    <Col span={11} style={{marginBottom: 12}}>
                        <p style={{marginBottom: 5}}>Phone Number</p>
                        <Input value={phone_number}/>
                    </Col>
                    <Col span={11} style={{marginBottom: 12}}>
                        <p style={{marginBottom: 5}}>Email</p>
                        <Input value={email}/>
                    </Col>
                    <Col span={11} style={{marginBottom: 12}}>
                        <p style={{marginBottom: 5}}>Company</p>
                        <Input value={company}/>
                    </Col>
                    <Col span={24}>
                        <p style={{marginBottom: 5}}>Question</p>
                        <TextArea rows={10} value={question}/>
                    </Col>
                    
                    {/* <Col span={24}>
                        <Button style={{float: 'right', marginTop: 25}} type="primary" loading={loading} onClick={submitForm}>Submit</Button>
                    </Col> */}
                    
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default Detail