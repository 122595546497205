import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import styled from "styled-components";

import axios from "axios";


const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  };

const tailLayout = {
    wrapperCol: { offset: 5, span: 16 },
};

const Login = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    console.log(values)
    axios
      .post("https://api.rajarental.co.id/api/v1.0/admin/auth/login", values)
      .then(({ data }) => {
        console.log(data)
        localStorage.setItem("token", data.response);
        setLoading(false);
          window.location.replace("/dashboard/admin");
        notification["success"]({
          message: "Log in success",
          description: "Welcome to Raja Rental Admin Dashboard",
        });
      })
      .catch(() => {
        setLoading(false);
        notification["warning"]({
          message: "Username or password wrong",
          description:
            "Please try again",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Wrapper>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ margin: 0 }}>Welcome</h1>
        <p>Log in to Raja Rental Admin Dashboard</p>
      </div>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" 
          loading={loading}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
}


const Wrapper = styled.div`
  width: 560px;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 0.1px solid grey;
  border-radius: 3px;
  padding-top: 32px;
`;

export default Login;