import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Card, Table, Tag, Space, Modal, Select } from "antd";
import { Link, useNavigate, useLocation, useHistory } from 'react-router-dom'
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

import logo from '../../../assets/logo.png'
import axios from "axios";

const { confirm } = Modal;
const { Option } = Select

const Product = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const token = localStorage.getItem("token")
  const [data, setData] = useState([])
  const [total, setTotal] = useState()
  const [current_page, setCurrent] = useState(1)
  const [tags, settags] = useState([])
  const [type, settype] = useState("")
  const [name, setname] = useState("")
  const [inputTagVisible, setinputvisible] = useState(false)
  const [inputTags, setinputtags] = useState("")

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
      key: 'subtitle',
    },
    {
      title: 'Type',
      dataIndex: 'article_type',
      key: 'article_type',
      defaultSortOrder: 'descend',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Link to={`/dashboard/article/detail?id=${record.id}`}>
            <Tag color={'geekblue'}>
              Ubah
            </Tag>
          </Link>

          <Button danger size="small" onClick={() => {
            confirm({
              title: 'Do you Want to delete these items?',
              icon: <ExclamationCircleOutlined />,
              onOk() {
                deleteData(record.id)
              },
              onCancel() {
                console.log('Cancel');
              },
            })
          }}>Delete</Button>
        </Space>
      ),
    },
  ];

  const deleteData = async (id) => {
    let del = await axios.delete('https://api.rajarental.co.id/api/v1.0/admin/article/' + id, {
      headers: {
        Authorization: token
      }
    })

    if (del) {
      navigate(location.pathname + location.search)
    }
  }

  const getList = async (query) => {
    let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/article', {
      headers: {
        Authorization: token
      },
      params: {
        ...query,
        pagination: true,
        row: 10,
      }
    })

    if (fetch) {
      setData(fetch.data.response.rows)
      setTotal(fetch.data.response.count)
    }
  }

  const deleteTag = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    settags(newTags);
  };

  const addTag = () => {
    if (inputTags && tags.indexOf(inputTags) === -1) {
      settags([...tags, inputTags]);
    }

    setinputvisible(false);
    setinputtags('');
  };

  const searchProduct = () => {
    let queryParams = "page=1"
    if (tags.length > 0) {
      queryParams += `&tag=${tags.join()}`
    }
    if (name !== "") {
      queryParams += `&name=${name}`
    }
    if (type !== "" && type !== 'all') {
      queryParams += `&article_type=${type}`
    }
    setCurrent(1)
    navigate("/dashboard/article?" + queryParams, { replace: true });
  }

  const pagination = (page) => {
    let allParam = location?.search?.split("&")
    if (location.search !== "") {
      for (let i = 0; i < allParam.length; i++) {
        let splitQuery = allParam[i].split("=")
        if (splitQuery[0].indexOf("page") !== -1) {
          splitQuery[1] = page
          allParam[i] = splitQuery.join("=")
        }
      }
    } else { allParam[0] = "?page=" + page }
    navigate("/dashboard/article" + allParam.join("&"), { replace: true });
  }

  useEffect(() => {
    if (location.search !== '') {
      let query = {}
      let allParam = location?.search?.split("&")
      for (let i = 0; i < allParam.length; i++) {
        let splitQuery = allParam[i].split("=")
        if (splitQuery[0].indexOf("tag") !== -1) {
          query.category_id = splitQuery[1]
          settags(splitQuery[1].split(","))
        } else if (splitQuery[0].indexOf("name") !== -1) {
          query.search = splitQuery[1]
          setname(splitQuery[1])
        } else if (splitQuery[0].indexOf("page") !== -1) {
          query.page = splitQuery[1]
          setCurrent(+splitQuery[1])
        } else if (splitQuery[0].indexOf("type") !== -1) {
          query.article_type = splitQuery[1]
          settype(splitQuery[1])
        }
      }
      getList(query)
    } else {
      getList({ page: current_page })
    }

  }, [location]);
  return (
    <div>
      <React.Fragment>
        <Card size="small" style={{ padding: "20px 30px" }}>
          <Form layout="vertical">
            <Row justify="space-between">
              <Col span={7}>
                <Form.Item label="Article Name">
                  <Input value={name} onChange={(e) => setname(e.target.value)} placeholder="Article Name" />
                </Form.Item>
              </Col>

              <Col span={7}>
                <Form.Item label="Article Type">
                  <Select style={{ display: 'block' }}
                    value={type}
                    onChange={(e) => settype(e)}
                  >
                    <Option value={"all"} title="All" /> 
                    <Option value={"portfolio"} title="Portofolio" />
                    <Option value={"news"} title="News" />
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Article Tag">
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    border: '1px solid #d9d9d9',
                    borderRadius: 2,
                    padding: '4px 11px 0 11px'
                  }}>
                    {
                      tags?.map((tag) => (
                        <Tag
                          key={tag}
                          closable={true}
                          style={{ marginBottom: 4 }}
                          onClose={() => deleteTag(tag)}
                        >{tag}</Tag>
                      ))
                    }
                    {inputTagVisible && (
                      <Input
                        // ref={inputRef}
                        type="text"
                        size="small"
                        className="tag-input"
                        value={inputTags}
                        onChange={(e) => setinputtags(e.target.value)}
                        onBlur={addTag}
                        onPressEnter={addTag}
                      />
                    )}
                    {!inputTagVisible && (
                      <Tag className="site-tag-plus" onClick={() => setinputvisible(true)}>
                        <PlusOutlined /> New Tag
                      </Tag>
                    )}
                  </div>
                </Form.Item>
              </Col>
              <Col span={5} push={19}>
                <Button style={{ float: 'right' }} type="primary" onClick={searchProduct}>Submit</Button>
              </Col>

            </Row>
          </Form>
        </Card>

        <Card size="small" style={{ padding: "20px 30px", marginTop: '30px' }}>
          <Row>
            <Col span={24}>
              <Button style={{ float: 'right', marginBottom: 25 }} type="primary" onClick={() => {
                window.location.href = '/dashboard/article/detail'
              }}>Add Article</Button>
            </Col>
          </Row>
          <Table columns={columns} dataSource={data}
            pagination={{
              current: current_page,
              total: total,
              onChange: pagination
            }} />
        </Card>
      </React.Fragment>

    </div>
  )
}

export default Product