import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "./Login";

import Dashboard from "./Dashboard/Dashboard";
import Product from './Dashboard/Product'
import ProductDetails from './Dashboard/Product/details'
import Admin from './Dashboard/Admin'
import AdminDetails from './Dashboard/Admin/details'
import Whatsapp from './Dashboard/Whatsapp'
import WhatsappDetails from './Dashboard/Whatsapp/details'
import Faq from './Dashboard/FAQ'
import FaqDetails from './Dashboard/FAQ/details'
import HowToOrder from './Dashboard/How-to-order'
import HowToOrderDetail from './Dashboard/How-to-order/details'
import Benefit from './Dashboard/Benefit'
import BenefitDetail from './Dashboard/Benefit/details'
import Email from './Dashboard/Email'
import EmailDetails from './Dashboard/Email/details'
import Footer from './Dashboard/Footer'
import FooterDetails from './Dashboard/Footer/details'
import Professional from './Dashboard/Professional'
import ProfessionalDetails from './Dashboard/Professional/details'
import Article from './Dashboard/Article'
import ArticleDetail from './Dashboard/Article/details'
import HomeBanner from './Dashboard/Home-Banner'
import HomeBannerDetail from './Dashboard/Home-Banner/details'
import Partner from './Dashboard/Partner'
import PartnerDetail from './Dashboard/Partner/details'
import Testimonial from './Dashboard/Testimonial'
import TestimonialDetail from './Dashboard/Testimonial/details'
import Variant from './Dashboard/Variant'
import VariantDetail from './Dashboard/Variant/details'
import ProductCat from './Dashboard/Product-category'
import ProductCatDetail from './Dashboard/Product-category/details'
import AboutUs from './Dashboard/About-us'
import Service from './Dashboard/Service'
import ServiceDetail from './Dashboard/Service/details'

const Components = () => {
  return (
    <Routes>
      <Route  path="/" element={<Login/>}/>

      <Route path="/dashboard" element={<Dashboard />}>
        <Route index path="product" element={<Product/>}/>
        <Route path="product" element={<Product/>}/>
        <Route path="product/detail" element={<ProductDetails/>}/>
        <Route path="admin" element={<Admin/>}/>
        <Route path="admin/detail" element={<AdminDetails/>}/>
        <Route path="Whatsapp" element={<Whatsapp/>}/>
        <Route path="Whatsapp/detail" element={<WhatsappDetails/>}/>
        <Route path="faq" element={<Faq/>}/>
        <Route path="faq/detail" element={<FaqDetails/>}/>
        <Route path="how-to-order" element={<HowToOrder/>}/>
        <Route path="how-to-order/detail" element={<HowToOrderDetail/>}/>
        <Route path="benefit" element={<Benefit/>}/>
        <Route path="benefit/detail" element={<BenefitDetail/>}/>
        <Route path="email" element={<Email/>}/>
        <Route path="email/detail" element={<EmailDetails/>}/>
        <Route path="content-footer" element={<Footer/>}/>
        <Route path="content-footer/detail" element={<FooterDetails/>}/>
        <Route path="home-professional" element={<Professional/>}/>
        <Route path="home-professional/detail" element={<ProfessionalDetails/>}/>
        <Route path="article" element={<Article/>}/>
        <Route path="article/detail" element={<ArticleDetail/>}/>
        <Route path="home-banner" element={<HomeBanner/>}/>
        <Route path="home-banner/detail" element={<HomeBannerDetail/>}/>
        <Route path="partner" element={<Partner/>}/>
        <Route path="partner/detail" element={<PartnerDetail/>}/>
        <Route path="testimonial" element={<Testimonial/>}/>
        <Route path="testimonial/detail" element={<TestimonialDetail/>}/>
        <Route path="variant" element={<Variant/>}/>
        <Route path="variant/detail" element={<VariantDetail/>}/>
        <Route path="product-category" element={<ProductCat/>}/>
        <Route path="product-category/detail" element={<ProductCatDetail/>}/>
        <Route path="about-us" element={<AboutUs/>}/>
        <Route path="service" element={<Service/>}/>
        <Route path="service/detail" element={<ServiceDetail/>}/>
      </Route>
      
    </Routes>
  );
};

export default Components;
