import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Card, Table, Tag, Space } from "antd";
import {Link} from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import logo from '../../../assets/logo.png'
import axios from "axios";
const { confirm } = Modal;

const Product = () => {
  const token = localStorage.getItem("token")
  const [data, setData] = useState([])
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Whatsapp Number',
          dataIndex: 'wa_number',
          key: 'wa_number',
        },
        {
          title: 'Status',
          dataIndex: 'active',
          key: 'active',
          render: (_, record) => (
              <Tag color={record.active ? 'green' : 'red'}>
                  {record.active ? "Active" : "Inactive"}
              </Tag>
            )
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <Link to={`/dashboard/whatsapp/detail?id=${record.id}`}>
                <Tag color={'geekblue'}>
                  Ubah
                </Tag>
              </Link>

              <Button danger size="small" onClick={() => {
                confirm({
                  title: 'Do you Want to delete these items?',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    deleteData(record.id)
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                })}}>Delete</Button>
            </Space>
          ),
        },
      ];

      const deleteData = async (id) => {
        let del = await axios.delete('https://api.rajarental.co.id/api/v1.0/admin/whatsapp-contact/' + id, {headers: {
          Authorization: token
        }})

        if(del) {
          getList()
        }
      }

      const getList = async () => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/whatsapp-contact', {headers: {
          Authorization: token
        }})

        if(fetch) {
          setData(fetch.data.response.rows)
          console.log(fetch)
        }
      }

      useEffect(() => {
        getList()
      }, [])
    return (
        <div>
            <React.Fragment>
               <Card size="small" style={{padding: "20px 30px", marginTop: '30px'}}>
                    {/* <Row>
                      <Col span={24}>
                        <Button style={{float: 'right', marginBottom: 25}} type="primary" onClick={() => {
                          window.location.href = '/dashboard/whatsapp/detail'
                        }}>Add Whatsapp</Button>
                      </Col>
                    </Row> */}
                    <Table columns={columns} dataSource={data} />
                </Card>
            </React.Fragment>
            
        </div>
    )
}

export default Product