import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Card, Table, Tag, notification } from "antd";
import {useLocation} from 'react-router-dom'
import styled from "styled-components";
import axios from 'axios'

const {TextArea} = Input

const Detail = () => {
    const token = localStorage.getItem("token")
    const loc = useLocation()
    const [question, setquestion] = useState("")
    const [answer, setAnswer] = useState("")
    const [oriData, setoridata] = useState({})
    const [loading, setLoading] = useState(false)

    const getData = async (id) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/faq/' + id, {headers: {
          Authorization: token
        }})

        if(fetch) {
            let data = fetch.data.response
          setquestion(data.question)
          setAnswer(data.answer)
          setoridata(data)
        }
      }

      useEffect(() => {
        if(loc.search && loc.search !== "") {
            getData(loc.search.split("?")[1].split("=")[1])
        }
      }, [])

      const submitForm = async () => {
          try {
              setLoading(true)
              
              if(loc.search && loc.search !== "") {
                  let id = loc.search.split("?")[1].split("=")[1]
                  let body = {}
                  let state = {
                      question,
                      answer
                  }
                  for(let key in oriData) {
                      if(oriData[key] != state[key]) {
                          body[key] = state[key]
                      }
                  }
                  if(question !== "" &&
                    answer !== ""
                ) {
                      let update = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/faq/' + id, 
                          body,{headers: {
                        Authorization: token
                    }})

                    if(update) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Edit Question detail",
                        });
                        window.location.href = "/dashboard/faq"
                    }
                  } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        description:
                          "Please try again",
                      });
                  }
                  
              } else {
                  if(question !== "" &&
                  answer !== "" ) {
                    let create = await axios.post('https://api.rajarental.co.id/api/v1.0/admin/faq/', {
                        question,
                          answer,
                    },{headers: {
                        Authorization: token
                    }})

                    if(create) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Add Question",
                        });
                        window.location.href = "/dashboard/faq"
                    }
                  } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        description:
                          "Please try again",
                      });
                  }
                
              }
          } catch (error) {

            console.log(error)
            setLoading(false)
            notification["warning"]({
                message: "Error",
                description:
                  "Please try again",
              });
          }
      }

    

    

    // console.log(username)

    return (
        <React.Fragment>
            <Card size="small" style={{padding: "20px 30px"}}>
                <Row justify="space-between">
                    <Col span={11}>
                        <p style={{marginBottom: 5}}>Question</p>
                        <TextArea rows={4} value={question} onChange={(e) => setquestion(e.target.value)}/>
                    </Col>
                    
                    <Col span={11}>
                        <p style={{marginBottom: 5}}>Answer</p>
                        <TextArea rows={4} value={answer} onChange={(e) => setAnswer(e.target.value)}/>
                    </Col>
 
                    <Col span={24}>
                        <Button style={{float: 'right', marginTop: 25}} type="primary" loading={loading} onClick={submitForm}>Submit</Button>
                    </Col>
                    
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default Detail