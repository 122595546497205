import React, { useState, useEffect } from "react";
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Tag, Input, Button, Row, Col, Card, Image, Upload, notification, Space, Select,  } from "antd";
import {useLocation} from 'react-router-dom'
import FormData from 'form-data'
import axios from 'axios'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';

const {TextArea} = Input
const {Option} = Select

const Detail = () => {
    const token = localStorage.getItem("token")
    const loc = useLocation()
    const [title, settitle] = useState("")
    const [subtitle, setsubtitle] = useState("")
    const [description, setdesc] = useState("")
    const [about_banner_image, setimage] = useState("")
    const [about_support_image, setbanner] = useState("")
    const [prevUrl, setprev] = useState({
        about_banner_image: "",
        about_support_image: "",
    })

    const [oriData, setoridata] = useState({})
    const [loading, setLoading] = useState(false)

    const getData = async () => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/about-us/' , {
            headers: {
          Authorization: token
        }})

        if(fetch) {
            let data = fetch.data.response
            let imageUrl = {
                about_banner_image: data.banner_image_source,
                about_support_image: data.support_image_source,
            }
            setimage(data.banner_image_source)
            setbanner(data.support_image_source)
            setprev(imageUrl)
            settitle(data.title)
            setsubtitle(data.subtitle)
            setdesc(data.description)
            setoridata({...data, ...imageUrl})
        }
      }

      useEffect(() => {
        getData()
      }, [])

      const submitForm = async (e) => {
          try {
              setLoading(true)
              e.preventDefault()
              let state = {
                    title,
                    subtitle,
                    description,
                    // about_banner_image,
                    // about_support_image,
                }
                let isEmpty = false
                let body = {}
                for(let key in state) {
                    if(state[key] === '' || state[key] === null || state[key] === undefined || state[key].length === 0) {
                        isEmpty = true
                    }
                    // body.append(key, state[key])
                    body[key] = state[key]
                }

                if(!isEmpty) {
                    let create = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/about-us/', 
                    body,
                    {headers: {
                        Authorization: token
                    }})

                    if(create) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Edit About us info",
                        });
                        getData()
                    }
                } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        description:
                            "Please try again",
                        });
                }
          } catch (error) {

            console.log(error)
            setLoading(false)
            notification["warning"]({
                message: "Error",
                description:
                  "Please try again",
              });
          }
      }

      const changeImg = async (key, { file, fileList }) => {
        if (file.status !== 'uploading') {
            if(key === 'about_banner_image') {
                setimage(file)
            } else if(key === 'about_support_image') {
                setbanner(file)
            }
            const src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
        
                reader.onloadend = () => resolve(reader.result);
              });

              setprev({...prevUrl, [key]:src})
        }
    }

    return (
        <React.Fragment>
            <Card size="small" style={{padding: "20px 30px"}}>
                <Row justify="space-between">
                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Title</p>
                        <Input value={title} onChange={(e) => settitle(e.target.value)}/>
                    </Col>
                    
                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Subtitle</p>
                        <Input value={subtitle} onChange={(e) => setsubtitle(e.target.value)}/>
                    </Col>

                    <Col span={24} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Description</p>
                        <TextArea rows={15} value={description} onChange={(e) => setdesc(e.target.value)}/>
                    </Col>

                    {/* <Col span={11} style={{marginTop: 25}}>
                        <p style={{marginBottom: 5}}>Banner Image</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            paddingLeft: '11px',
                        }} wrap>
                            <div style={{width: '73%'}}>
                                <p className="elipsis" style={{marginBottom: '0px', marginTop: '4px'}}>{about_banner_image?.name ? about_banner_image.name : about_banner_image}</p>
                            </div>
                            
                            <Upload 
                            style={{height: '100%'}}
                            showUploadList={false}
                            beforeUpload={() => {
                                    return false;
                                }}                      
                                onChange={(data) => changeImg('about_banner_image', data)}>
                                    <Button style={{borderWidth: 0, borderLeftWidth: '1px', height: '100%'}} icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                            <Image src={prevUrl.about_banner_image} style={{marginTop: '20px'}}/>
                    </Col> */}

                    {/* <Col span={11} style={{marginTop: 25}}>
                        <p style={{marginBottom: 5}}>Suppport image</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            paddingLeft: '11px',
                        }} wrap>
                            <div style={{width: '73%'}}>
                                <p className="elipsis" style={{marginBottom: '0px', marginTop: '4px'}}>{about_support_image?.name ? about_support_image.name : about_support_image}</p>
                            </div>
                            
                            <Upload 
                            style={{height: '100%'}}
                            showUploadList={false}
                            beforeUpload={() => {
                                    return false;
                                }}                      
                                onChange={(data) => changeImg('about_support_image', data)}>
                                    <Button style={{borderWidth: 0, borderLeftWidth: '1px', height: '100%'}} icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                            <Image src={prevUrl.about_support_image} style={{marginTop: '20px'}}/>
                    </Col> */}
 
                    <Col span={24}>
                        <Button style={{float: 'right', marginTop: 25}} type="primary" loading={loading} onClick={submitForm}>Submit</Button>
                    </Col>
                    
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default Detail