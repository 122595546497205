import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Card, Table, Tag, notification } from "antd";
import {useLocation} from 'react-router-dom'
import styled from "styled-components";
import axios from 'axios'

const {TextArea} = Input

const Detail = () => {
    const token = localStorage.getItem("token")
    const loc = useLocation()
    const [name, setname] = useState("")
    const [value, setvalue] = useState("")
    const [oriData, setoridata] = useState({})
    const [loading, setLoading] = useState(false)

    const getData = async (id) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/content-footer/' + id, {headers: {
          Authorization: token
        }})

        if(fetch) {
            let data = fetch.data.response
          setname(data.name)
          setvalue(data.value)
          setoridata(data)
        }
      }

      useEffect(() => {
        if(loc.search && loc.search !== "") {
            getData(loc.search.split("?")[1].split("=")[1])
        }
      }, [])

      const submitForm = async () => {
          try {
              setLoading(true)
              
              if(loc.search && loc.search !== "") {
                  let id = loc.search.split("?")[1].split("=")[1]
                  let body = {}
                  let state = {
                      name,
                      value
                  }
                  let isValid;
                  for(let key in oriData) {
                      if(oriData[key] != state[key]) {
                          body[key] = state[key]
                          if(state[key] !== "") isValid =  true
                      }
                  }
                  if(name.split("_")[1] == "phone") {
                        let checking = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/.test(value)
                        if(!checking) { isValid = false}
                        else {isValid = true}

                    }
                  if(isValid) {
                      let update = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/content-footer/' + id, 
                          body,{headers: {
                        Authorization: token
                    }})

                    if(update) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Edit Footer Content detail",
                        });
                        window.location.href = "/dashboard/content-footer"
                    }
                  } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi dan pastikan sesuai syarat ketentuan",
                        description:
                          "Please try again",
                      });
                  }
                  
              } 
          } catch (error) {

            console.log(error)
            setLoading(false)
            notification["warning"]({
                message: "Error",
                description:
                  "Please try again",
              });
          }
      }

    

    

    // console.log(username)

    return (
        <React.Fragment>
            <Card size="small" style={{padding: "20px 30px"}}>
                <Row justify="space-between">
                    <Col span={11}>
                        <p style={{marginBottom: 5}}>Title</p>
                        <Input value={name} onChange={(e) => setname(e.target.value)} disabled/>
                    </Col>
                    
                    <Col span={11}>
                        <p style={{marginBottom: 5}}>Value</p>
                        <TextArea rows={4} value={value} onChange={(e) => setvalue(e.target.value)}/>
                    </Col>
 
                    <Col span={24}>
                        <Button style={{float: 'right', marginTop: 25}} type="primary" loading={loading} onClick={submitForm}>Submit</Button>
                    </Col>
                    
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default Detail