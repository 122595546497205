import React, { useState, useEffect } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Form, Input, Button, Row, Col, Card, Image, Upload, notification } from "antd";
import {useLocation} from 'react-router-dom'
import FormData from 'form-data'
import axios from 'axios'

const {TextArea} = Input

const Detail = () => {
    const token = localStorage.getItem("token")
    const loc = useLocation()
    const [name, setname] = useState("")
    const [testimony, settestimony] = useState("")
    const [avatar_image, setimage] = useState("")
    const [prevUrl, setprev] = useState("")
    const [oriData, setoridata] = useState({})
    const [loading, setLoading] = useState(false)

    const getData = async (id) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/testimonial/' + id, {headers: {
          Authorization: token
        }})

        if(fetch) {
            let data = fetch.data.response
            console.log(fetch.data.response)
            setimage(data.avatar_source)
            setprev(data.avatar_source)
            settestimony(data.testimony)
            setname(data.name)
            setoridata({...data, avatar_image: data.avatar_source})
          
        }
      }

      useEffect(() => {
        if(loc.search && loc.search !== "") {
            getData(loc.search.split("?")[1].split("=")[1])
        }
      }, [])

      const submitForm = async (e) => {
          try {
                setLoading(true)
                e.preventDefault()
                let state = {
                      name,
                      testimony,
                }
                let body = new FormData()
                let isEmpty = false
                for(let key in state) {
                    if(state[key] === '' || state[key] === null || state[key] === undefined || state[key].length === 0) {
                        isEmpty = true
                    }
                }
                if(avatar_image !== '' || avatar_image !== null){
                    body.append('avatar_image', avatar_image)
                }
                
              if(loc.search && loc.search !== "") {
                  let id = loc.search.split("?")[1].split("=")[1]
                  for(let key in state) {
                      if((oriData[key] != state[key]) && state[key] !== undefined) {
                          body.append(key,state[key])
                      }
                  }

                  if(!isEmpty) {
                      let update = await axios.put('https://api.rajarental.co.id/api/v1.0/admin/testimonial/' + id,
                          body,{
                        headers: {
                            Authorization: token,
                            'Content-Type': `multipart/form-data; boundary=${body._boundary}`,
                        }})

                    if(update) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Edit testimonial detail",
                        });
                        window.location.href = "/dashboard/testimonial"
                    }
                  } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        description:
                          "Please try again",
                      });
                  }
                  
              } else {
                for(let key in state) {
                    body.append(key, state[key])
                }
                if(!isEmpty) {
                    let create = await axios.post('https://api.rajarental.co.id/api/v1.0/admin/testimonial/', 
                    body,
                    {headers: {
                        Authorization: token
                    }})

                    if(create) {
                        setLoading(false)
                        notification["success"]({
                            message: "Success Add testimonial",
                        });
                        window.location.href = "/dashboard/testimonial"
                    }
                } else {
                    setLoading(false)
                    notification["warning"]({
                        message: "Semua field wajib diisi",
                        description:
                            "Please try again",
                        });
                }
              }
          } catch (error) {

            console.log(error)
            setLoading(false)
            notification["warning"]({
                message: "Error",
                description:
                  "Please try again",
              });
          }
      }

      const changeImg = async ({ file, fileList }) => {
        if (file.status !== 'uploading') {
            setimage(file)
            const src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
        
                reader.onloadend = () => resolve(reader.result);
              });

              setprev(src)
            console.log(file);
        }
    }

    return (
        <React.Fragment>
            <Card size="small" style={{padding: "20px 30px"}}>
                <Row justify="space-between">
                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Name</p>
                        <Input value={name} onChange={(e) => setname(e.target.value)}/>
                    </Col>
                    
                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Testimony</p>
                        <TextArea rows={4} value={testimony} onChange={(e) => e.target.value.length <= 100 && settestimony(e.target.value)}/>
                        <p style={{fontSize: '0.8rem'}}>*maksimal 100 karakter</p>
                    </Col>

                    <Col span={11} style={{marginBottom: 10}}>
                        <p style={{marginBottom: 5}}>Icon</p>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            border: '1px solid #d9d9d9',
                            borderRadius: '2px',
                            paddingLeft: '11px',
                        }} wrap>
                            <div style={{width: '73%'}}>
                                <p className="elipsis" style={{marginBottom: '0px', marginTop: '4px'}}>{avatar_image?.name ? avatar_image.name : avatar_image}</p>
                            </div>
                            
                            <Upload 
                            style={{height: '100%'}}
                            showUploadList={false}
                            beforeUpload={() => {
                                return false;
                              }}                      
                            onChange={changeImg}>
                                <Button style={{borderWidth: 0, borderLeftWidth: '1px', height: '100%'}} icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </div>
                        <Image src={prevUrl} style={{marginTop: '20px'}}/>
                    </Col>

                    <Col span={24}>
                        <Button style={{float: 'right', marginTop: 25}} type="primary" loading={loading} onClick={submitForm}>Submit</Button>
                    </Col>
                    
                </Row>
            </Card>
        </React.Fragment>
    )
}

export default Detail