import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Card, Table, Tag, Space, Modal, Select } from "antd";
import {Link, useNavigate, useLocation} from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import logo from '../../../assets/logo.png'
import axios from "axios";

const { confirm } = Modal;
const {Option} = Select

const Product = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const token = localStorage.getItem("token")
  const [data, setData] = useState([])
  const [total, setTotal] = useState()
  const [categories, setcategories] = useState([])
  const [category, setcategory] = useState("")
  const [prod_name, setname] = useState("")
  const [current_page, setCurrent] = useState(1)
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Short Description',
          dataIndex: 'short_desc',
          key: 'short_desc',
        },
        {
          title: 'Lineup status',
          dataIndex: 'is_lineup',
          key: 'is_lineup',
          defaultSortOrder: 'descend',
          render: (_, record) => (
            <Space size="middle">
                {
                  record.is_lineup ? (
                    <Tag color={'green'}>
                      Active
                    </Tag>
                  ) : (
                    <Tag color={'red'}>
                      Inactive
                    </Tag>
                  )
                }
            </Space>
          ),
          // sorter: (a, b) => a.is_lineup - b.is_lineup,
          // filters: [
          //   {
          //     text: 'Active',
          //     value: true,
          //   },
          //   {
          //     text: 'Inactive',
          //     value: false
          //   }
          // ],
          // onFilter: (value, record) => record.is_lineup === value,
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <Link to={`/dashboard/product/detail?id=${record.id}`}>
                <Tag color={'geekblue'}>
                  Ubah
                </Tag>
              </Link>

              <Button danger size="small" onClick={() => {confirm({
                  title: 'Do you Want to delete these items?',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    deleteData(record.id)
                  },
                  onCancel() {
                    console.log('Cancel');
                  },
                })}}>Delete</Button>
            </Space>
          ),
        },
      ];

      const getCategory = async (page) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/product-category', {
          headers: {
            Authorization: token
          },
          params: {
            pagination: false,
          }
        })

        if(fetch) {
          setcategories(fetch.data.response.rows)
        }
      }

      const deleteData = async (id) => {
        let del = await axios.delete('https://api.rajarental.co.id/api/v1.0/admin/product/' + id, {headers: {
          Authorization: token
        }})

        if(del) {
          getList()
        }
      }

      const getList = async (query) => {
        let fetch = await axios.get('https://api.rajarental.co.id/api/v1.0/admin/product', {
          headers: {
            Authorization: token
          },
          params: {
            ...query,
            pagination: true,
            row: 10,
          }
        })

        if(fetch) {
          setData(fetch.data.response.rows)
          setTotal(fetch.data.response.count)
        }
      }

      const searchProduct = () => {
        let queryParams = "page=1"
        if(category !== "" && category !== -1) {
          queryParams += `&cat=${category}`
        }
        if(prod_name !== "") {
          queryParams += `&name=${prod_name}`
        }
        setCurrent(1)
        navigate("/dashboard/product?" + queryParams, { replace: true });
      }

      const pagination = (page) => {
        let allParam = location?.search?.split("&")  
        if(location.search !== "") {
            for(let i = 0; i < allParam.length; i++) {
                let splitQuery = allParam[i].split("=")
                if(splitQuery[0].indexOf("page") !== -1) {
                  splitQuery[1] = page
                  allParam[i] = splitQuery.join("=")
                }
            }
        } else {allParam[0] = "?page=" + page}
        navigate("/dashboard/product" + allParam.join("&"), { replace: true });
      }

      useEffect(() => {
        if(location.search !== '') {
          let query = {}
          let allParam = location?.search?.split("&")  
          for(let i = 0; i < allParam.length; i++) {
              let splitQuery = allParam[i].split("=")
              if(splitQuery[0].indexOf("cat") !== -1) {
                query.category_id = splitQuery[1]
                setcategory(+splitQuery[1])
              } else if (splitQuery[0].indexOf("name") !== -1) {
                query.search = splitQuery[1]
                setname(splitQuery[1])
              } else if (splitQuery[0].indexOf("page") !== -1) {
                query.page = splitQuery[1]
                setCurrent(+splitQuery[1])
              }
          }
          getList(query)
          getCategory()
        } else {
          getList({page: current_page})
          getCategory()
        }
        
      }, [location]);
      console.log(location, 'locc')
    return (
        <div>
            <React.Fragment>
                <Card size="small" style={{padding: "20px 30px"}}>
                    <Form layout="vertical">
                        <Row justify="space-between">
                            <Col span={10}>
                                <Form.Item label="Product Name">
                                    <Input value={prod_name} onChange={(e) => setname(e.target.value)} placeholder="Nama produk"/>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="Product Category">
                                <Select style={{display: 'block'}} 
                                    value={category} 
                                    onChange={(e) => setcategory(e)}
                                    placeholder="Pilih kategori"
                                >
                                  <Option value={-1} >Semua</Option>
                                    {
                                        categories?.map(cat => (
                                            <Option value={cat.id} key={cat.id}>{cat.name}</Option>
                                        ))
                                    }
                                </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5} push={19}>
                                <Button style={{float: 'right'}} type="primary" onClick={searchProduct}>Submit</Button>
                            </Col>
                            
                        </Row>
                    </Form>
                </Card>

                <Card size="small" style={{padding: "20px 30px", marginTop: '30px'}}>
                    <Row>
                      <Col span={24}>
                        <Button style={{float: 'right', marginBottom: 25}} type="primary" onClick={() => {
                          window.location.href = '/dashboard/product/detail'
                        }}>Add Product</Button>
                      </Col>
                    </Row>
                    <Table columns={columns} dataSource={data} 
                    pagination={{
                      current: current_page,
                      total: total,
                      onChange: pagination
                    }}/>
                </Card>
            </React.Fragment>
            
        </div>
    )
}

export default Product